import { render, staticRenderFns } from "./NewLogin.vue?vue&type=template&id=dc2af214&scoped=true&"
import script from "./NewLogin.vue?vue&type=script&lang=js&"
export * from "./NewLogin.vue?vue&type=script&lang=js&"
import style0 from "./NewLogin.vue?vue&type=style&index=0&id=dc2af214&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc2af214",
  null
  
)

export default component.exports